.info{
    padding: 0rem 1rem 1rem 1rem;
    background-color: white;
    margin-bottom: 0.5rem;
}

.info p {
    text-align: justify;
}

.info h4{
    margin : 1rem;
}

.info-title{
    display: grid;
    grid-template-columns: 3fr auto;
    align-items: center;
}

.info-date{
    display: grid;
    grid-template-columns: 2fr auto;
    align-items: center;
    justify-items: end;
}

.info-date p{
    font-size: small;
    color: gray;
    margin-right : 0.3rem;
}