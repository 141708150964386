

html { 
    scroll-behavior: smooth; 
}

p {
    margin: 0px;
    font-family: 'Varela';
}

a{
    text-decoration: none;
    color: black;
    font-family: 'Varela';
}

:root{
    --themeColor : #72AFE0;
    --imporantColor : #D39F93;
    --textColorLight : white;
    --textColorDark : black;
    --fontFamily : 'Varela';
}
.important-btn{
    border: none;
    background-color: var(--imporantColor);
    border-radius: 25px;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.pdf{
    display: flex;
    align-items: center;
    margin: 1rem;
}

.pdf img{
    width: 58px;
    margin-right: 1rem;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 500px;
    max-width: 800px;
    margin : auto
}

.link{
    display: flex;
}

/* MEDIA SCREEN */
@media screen and (max-width: 1000px){
    .content{
        padding-left: 5% ;
        padding-right: 5%;
        margin-bottom: 3rem;
    }
}

/* MEDIA SCREEN */
@media screen and (max-width: 800px){
    .link{
        flex-direction: column;
    }    
    .link a{
        margin-top: 1.5rem;
    }
}


