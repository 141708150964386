.event{
    padding: 0rem 1rem 1rem 1rem;
    background-color: white;
    margin-bottom: 0.5rem;
}

.event p {
    text-align: justify;
}

.event h4{
    margin : 1rem;
    font-family: 'Varela';
}

.event-title{
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
}

.event-date{
    display: grid;
    grid-template-columns: 2fr auto;
    align-items: center;
    justify-items: end;
}

.event-date p{
    font-size: small;
    color: gray;
    margin-right : 0.3rem;
}

.event-location {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
}

.event-location p{
    margin-left: 1rem;
    font-size: small;
    color: gray;
    margin-right : 0.3rem;
}

.event-short{
    margin: 1rem;
}

.event-footer{
    display: grid;
    align-items: center;
    justify-items: end;
}